.ContactMe-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 71%;
    font-size: 2em;
    background-color: rgba(36, 36, 36, 0.449);
    float: left;
    margin-left: 1%;
    margin-top: 1%;
    border-radius: 20px;
    border: 5px solid white;
    backdrop-filter: blur(3.5px);
}
.ContactMe-container h1{
    color: white;
    margin-top: 0;
    top: 0px;
    height: fit-content;
    font-weight: 100;
    margin-bottom: 10px;
    float: left;
}
.my-pic{
    width: 27vh;
    border: 5px solid white;
    border-radius: 50%;
    margin-top: 2vh;
    animation: wheel 2.5s linear infinite;
    z-index: -1;
}
@keyframes wheel {
    0%{
       scale:1;
    }
    50%{
        scale:1.1;
    }
    100%{
        scale: 1;
    }
}
.ContactMe{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 0;
    overflow-y: scroll;
    height: 100%;
    font-size: .5em;
}
.social-link{
    text-decoration: none;
    border: 2px solid white;
    height: 50%;
    transition: 500ms;
    border-radius: 50px;
}
.social-link:hover{
    border: 2px solid white;
    height: 95%;
    background-color: rgba(0, 128, 0, 0.456);
    border-color: greenyellow;
}
.ContactMe div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: white;

}
.ContactMe svg{
    fill: white;
    margin-bottom: 25px;
}

.social-link:hover + .my-pic{
    transform: translateY(-10vw);
}
