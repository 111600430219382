.project-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 71%;
    font-size: 2em;
    background-color: rgba(36, 36, 36, 0.449);
    float: left;
    margin-left: 1%;
    margin-top: 1%;
    border-radius: 20px;
    border: 5px solid white;
    backdrop-filter: blur(3.5px);
}
.project-container h1{
    color: white;
    margin-top: 0;
    top: 0px;
    height: fit-content;
    font-weight: 100;
    margin-bottom: 10px;
    float: left;
}

.projects{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    margin: 0;
    overflow-y: scroll;

}

.project{
    width: 100% !important;
    height: auto !important;
    float: left !important;
    /* border: 3px solid gray;
    background-color: rgba(0, 0, 0, 0.302) !important;
    border-radius: 10px; */
    position: relative;
    margin-bottom: 10px;
}

.project-description{
    width: 100%;
    font-size: .5em !important;
    float: right;
    color: rgb(255, 255, 255);
    padding-bottom: 5px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.445);
}

.project-title{
    font-weight: 700;
}

.project-description a{
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 10px;
    float: right;
    margin-right: 5%;
    border-bottom: 3px solid white;
    color: white;
}
.project-description a:hover{
    border-bottom: 3px solid white;
    color: white;
}
.line{
    width: 80%;
    height: 2px !important;
    background-color: rgba(255, 255, 255, 0.461);
    border-radius: 10px;
    margin: 5px;
}
