.nav-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    width: 25%;
    float: right;
    font-size: 1em;
}

.nav{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    position: fixed;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 100%;
    margin-right: 1%;
    background: linear-gradient(-45deg, #ee765259, #e73c7e59, #2516f759, #23d56a59);
    animation: gradient 6s ease infinite;
    backdrop-filter: blur(7px);
    backdrop-filter: invert(60%);
    width: 6vw;
    height: 60vh;
    /* padding: 5px; */
    border-radius: 7px;
    border: 5px solid rgb(211, 211, 211);
    transition: width 500ms linear;
    background-size: 200% 200%;
    filter: brightness(125%);
}
@keyframes gradient {
    0% {
        background-position: 0% 100%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 100%;
    }
}

.nav:hover
{
    width: 27vw;
    animation: gradient 9s ease infinite;
    background-size: 600% 200%;
}

.section{
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 33.33%;
    transition: 200ms linear;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.452);
}

.section svg{
    position: absolute;
    left: 14%;
    transition: 500ms left linear;
}
.nav:hover svg{
    left: 0;
    right: 100%;
}
.nav:hover .section{
    visibility: visible;
    letter-spacing: 5px;
}
.nav samp{
    cursor: pointer;
    width: fit-content;
    color: rgba(0, 0, 0, 0);
    font-size: 1em;
}

.nav .section:hover{
    color: green;
    border: 2px solid rgba(0, 255, 13, 0.4);
    background-color: rgba(123, 249, 123, 0.267);
    font-weight: 700;
}

.nav:hover samp{
    color: inherit;
}

/* button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    position: relative;
} */

.hide{
    visibility: hidden;
 }
