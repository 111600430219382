/************ Custom Font ************/

*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    background-image: url(./components/background.jpg);
    z-index: -99999999999999999;
    position: relative;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -9999;
}
