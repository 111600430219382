.WorkExperience-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    width: 71%;
    font-size: 2em;
    background-color: rgba(123, 121, 121, 0.449);
    float: left;
    margin-left: 1%;
    margin-top: 1%;
    border-radius: 20px;
    border: 5px solid white;
    backdrop-filter: blur(7px);
}

.WorkExperience-container h1{
    color: white;
    margin: auto;
    margin-top: 0;
    top: 0px;
    height: fit-content;
    font-weight: 100;
}
